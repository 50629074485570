import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import './Pooja.css'; // Import your CSS file
import image1 from './IMAGES/pooja1.jpeg'
import image2 from './IMAGES/pooja2.jpeg'
import image3 from './IMAGES/pooja3.jpeg'
import image4 from './IMAGES/pooja7.jpeg'
import image5 from './IMAGES/pooja8.jpeg'
import image6 from './IMAGES/pooja9.jpeg'
import image7 from './IMAGES/pooja10.jpeg'
import image8 from './IMAGES/pooja11.jpeg'
import stones from './IMAGES/stones.jpeg'
import naal from './IMAGES/naal.jpeg'
import pyramid from  './IMAGES/pyramid.jpeg'
import trishul from './IMAGES/pooja4.jpeg'
import saap from './IMAGES/pooja5.jpeg'
import naal2 from './IMAGES/pooja6.jpeg'

const galleryImage = [
  { id: "1", image: image1},
  { id: "2", image: image2},
  { id: "3", image: image3 },
  { id: "4", image: image4 },
  { id: "5", image: image5 },
  { id: "6", image: image6 },
  { id: "7", image: image7},
  { id: "8", image: image8},
];
const vastu=[
    { id: "1", image:naal},
    { id: "2", image:pyramid},
    { id: "3", image:trishul},
    { id: "4", image:saap},
    { id: "5", image:naal2},
    { id: "6", image:stones},
  
]


const Pooja = () => {
 
  return (
    <Container fluid>
            <div className='subheader-details'>
                <p className='pooja-head'>विशेष पूजा</p>
                <p>नारायण नागबली, कालसर्पयोग विधी, नक्षत्र शांती योगशांती, वशीकरण  अशा सर्व धार्मिक पद्धतीच्या विधी पूजा पाठ शास्त्रश्रोत पद्धतीने करून दिल्या जातील.</p>
            </div>
            <Row className='special-puja-row'>
                <Gallery>
                    {galleryImage.map((image) => {
                        return (
                            <Col sm={12} md={6} lg={4} key={image.id} className='special-puja-col'>
                                <Item
                                    original={image.image}
                                    thumbnail={image.image}
                                    width="800"
                                    height="600"
                                    
                                >
                                    {({ ref, open }) => (
                                        <img
                                            src={image.image}
                                            onClick={open}
                                            ref={ref}
                                            className="galleryimg"
                                            alt='img'
                                        />
                                    )}
                                </Item>
                            </Col>
                        );
                    })}
                </Gallery>
            </Row >
            <p className='vastu-head'>सर्व सिद्ध अनुष्ठान  झालेल्या वस्तू  मिळतील .</p>
            <Row className='special-puja-row'>
            
            <Gallery>
                    {vastu.map((image) => {
                        return (
                            <Col sm={12} md={6} lg={4} key={image.id} className='special-puja-col'>
                                <Item
                                    original={image.image}
                                    thumbnail={image.image}
                                    width="800"
                                    height="600"
                                    
                                >
                                    {({ ref, open }) => (
                                        <img
                                            src={image.image}
                                            onClick={open}
                                            ref={ref}
                                            className="galleryimg"
                                            alt='img'
                                        />
                                    )}
                                </Item>
                            </Col>
                        );
                    })}
                </Gallery>
            </Row>
        </Container>
  );
};

export default Pooja;
