import React from "react";
import Slider from "react-slick";
import "./Services.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Jyotishshashtra from './IMAGES/New-Project-2023-04-10T154942.781.webp';
import Ratnashastra from './IMAGES/How-to-Wear-Lucky-Gemstones-1000x600.jpg';
import janmkundali from './IMAGES/janam-kundali2.png';
import vastushastra from './IMAGES/Vastu-Shastra-Anusar-Ghar.jpg';
import hastreshashastra from './IMAGES/collage-maker-18-dec-2022-09.49-pm-sixteen_nine.webp';
import lovemarriage from './IMAGES/3.webp';
import angrycouple from './IMAGES/angry-couple.jpg';

const Service = () => {
  const services = [
    {
      src: Jyotishshashtra,
      heading: "ज्योतिषशास्त्र",
      text: "आयुष्यात अनेकवेळा असे घडते की आपण कठोर परिश्रम करूनही आपल्याला अपेक्षित यश मिळत नाही, विनाकारण कलंक लागतो, आपल्याला वारंवार आजार होतात, अशा वेळी मन खूप विचलित होते, निराश होते, काही समजत नाही. . अशा वेळी ‘सूर्य’ सारख्या समस्यांच्या रूपाने अंधार दूर करण्यात ज्योतिषशास्त्र समर्थ आहे. ज्योतिषशास्त्राच्या माध्यमातून या समस्यांची कारणे शोधून, प्रतिकूल ग्रहांचे शांती-उपाय करून, अनुकूल ग्रहांचे बळकटीकरण करून आपण आपले जीवन भौतिक, आर्थिक आणि आध्यात्मिकदृष्ट्या पूर्ण करू शकतो.",
    },
    {
      src: Ratnashastra,
      heading: "रत्न रहस्य",
      text: "जन्मपत्रिकेत असलेल्या ग्रहांच्या प्रभावापासून आपण सुटू शकत नाही, करक ग्रहांच्या दशामध्ये आपण विलासी आणि आनंदी जीवन जगतो आणि कारक नसलेल्या ग्रहांच्या दशामध्ये दुःख आणि संघर्ष सहन करतो. अशा वेळी ग्रहांना अनुकूल बनवण्यासाठी प्रातिनिधिक रत्नांचा आधार घ्यावा लागतो, प्रत्येक ग्रहाला एक प्रातिनिधिक रत्न असते. रत्न हे शक्तिवर्धक असतात जे आपल्या ग्रहांना विशेष शक्ती देतात. रत्नांना मंत्रोच्चार करून विशिष्ट क्षणी धारण केल्यास आणि त्यांच्याशी संबंधित मंत्रांचा जप केल्यास अलौकिक यश प्राप्त होते.",
    },
    {
      src: janmkundali,
      heading: "जन्म कुंडली",
      text: "पंडित गणेशजी शास्त्री तुमच्या पत्रिकांचे विश्लेषण करून तुमच्या भविष्यासाठी सर्वोत्तम उपाय देतात आणि त्यानुसार तुमच्या जीवनात प्रगती कशी करावी याचे मार्गदर्शन करतात.",
    },
    {
      src: vastushastra,
      heading: "वास्तुशास्त्र",
      text: "सुंदर घर असणे पुरेसे नाही; तुम्हाला तिथे आनंदी वाटले पाहिजे. वास्तुशास्त्र तुम्हाला असे स्थान निर्माण करण्यात मदत करते जे तुमच्या जीवनाशी सुसंगत असते. हे घर, इमारती आणि व्यावसायिक ठिकाणांच्या ऊर्जांना परिभाषित करते आणि आकार देते, ज्यामुळे रहिवाशांना समृद्धी आणि मानसिक शांती मिळते.",
    },
    {
      src: hastreshashastra,
      heading: "हस्तरेषाशास्त्र",
      text: "नशीब संपले? तुमच्या व्यवसायात प्रचंड नुकसान झाले आहे? तुमच्या परीक्षेत अपेक्षित निकाल मिळवण्यात अयशस्वी झालात? तुमच्या सर्व अडथळ्यांवर आमच्याकडे उपाय आहेत. ग्रहांची स्थिती सध्या तुमच्या दयेवर नाही. प्रख्यात ज्योतिषी पंडित गणेशजी शास्त्री तुम्हाला सततच्या अडथळ्यातून सावरण्यास मदत करू शकतात. हस्तरेषाशास्त्रातील त्यांचा अनुभव तुम्हाला भारनियमनाबद्दल वारंवार समजून घेण्यास अनुमती देईल .",
    },
    {
      src: lovemarriage,
      heading: "प्रेम विवाह तज्ञ",
      text: "तुम्हाला आपल्या प्रेमात असलेल्या व्यक्तीशी लग्न करायचे आहे का? येथे तुम्हाला तुमच्या समस्येचे समाधान मिळेल. ज्या व्यक्तीशी तुम्हाला प्रेम आहे त्याच्याशी लग्न करण्यासाठी ज्योतिषाचा वापर करा, जो सर्वोत्तम उपाय आहे.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };

  return (
    <div>
      <div className="main-container">
        <section className="image-section">
          <img src={angrycouple} alt="Image" />
        </section>
        <section className="content-section">
          <p className="content-text">
            प्रेमा संधर्बाद निराश झालेल्या व्यक्तींनी शास्त्रीजींना अवश्य कॉल करा
          </p>
          <a href="tel:+917558447044">
            <button className="call-button">Call Now</button>
          </a>
        </section>
      </div>

      <div className="service_section">
        <div>
          <div className='subheader-details'>
            <p className="service-head">आमच्या सेवा</p>
            <p className="service-p">आमची सेवा आपल्या सर्व अडचणीं वर उपाय देण्यास मार्गदर्शन करते.</p>
          </div>
          <div className="service_main_section">
            <Slider {...settings}>
              {services.map((item, index) => (
                <div key={index} className="service_card_wrapper">
                  <img src={item.src} alt={item.heading} />
                  <div>
                    <h4>{item.heading}</h4>
                    <p>{item.text}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
