import './App.css';
import Home from './Home';
import Navrouter from './Navrouter';
import Services from './Services'
import "bootstrap/dist/css/bootstrap.min.css";
import Yantra from './Yantra';
import Stones from './Stones';
import Contact from './Contact';
import Footer from './Footer';
import Pooja from './Pooja'

function App() {
  return (
    <div >
      <section>
        <Navrouter/>
      </section>
      <section id='home'>
        <Home/>
      </section>
      <section id='services'>
        <Services/>
      </section >
      <section id='yantra'>
        <Yantra/>
      </section>
      <section id='pooja'>
        <Pooja/>
      </section>
      <section id='stones'>
        <Stones/>
      </section >
      <section id='contact'> 
        <Contact/>
      </section>
      <section>
        <Footer/>
      </section>
    </div>
  );
}

export default App;
