import React from 'react';
import './Home.css'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import home1 from './IMAGES/home1.png';
import home2 from './IMAGES/image.png';
import home3 from './IMAGES/jyotish_shastra-sixteen_nine.jpg';
import { Col, Container, Row } from 'react-bootstrap';
import one from './IMAGES/1-2.png'
import two from './IMAGES/2-2.png'
import three from './IMAGES/3-2.png'

const Home = () => {
  return (
    <div className="home">
      <Carousel showArrows={true} showThumbs={false} autoPlay={true} infiniteLoop={true}>
        <div
          className="slide-item"
          style={{
            backgroundImage: `url(${home1})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '53vw',
            position: 'relative',
          }}
        >
          <div className="text-container">
            <h2 className='text-container-h2 '>पंडित गणेशजी शास्त्री</h2>
            <p>* ज्योतिषशास्त्र * जन्म कुंडली * वास्तुशास्त्र</p>
          <a href="tel:+917558447044">  <button className="custom-button">Call Us Now</button></a>
          </div>
        </div>
        <div className="slide-item">
          <img src={home2} alt="Slide 2" style={{ height: '53vw', objectFit: 'cover' }} />
        </div>
        <div className="slide-item">
          <img src={home3} alt="Slide 3" />
        </div>
        {/* Add more slides as needed */}
      </Carousel>
      <Container fluid className='home-Why-section'>
        <h4>Why Choose Us</h4>
        <h5>तुम्हाला अपवादात्मक सेवा प्रदान करण्याच्या आमच्या क्षमतेवर आमचा ठाम विश्वास आहे.</h5>
        <Row className='why-section-row'>
          <Col sm={12} md={12} lg={4} className='why-section-coloums'>
            <div className='contents'>
              <img src={one} alt='' />
              <h6>अचूक उपचार उपाय</h6>
            </div>
          </Col>
          <Col sm={12} md={12} lg={4} className='why-section-coloums'>
            <div className='contents'>
              <img src={two} alt='' />
              <h6>गोपनीयतेची हमी</h6>
            </div>
          </Col>
          <Col sm={12} md={12} lg={4} className='why-section-coloums'>
            <div className='contents'>
              <img src={three}  alt=''/>
              <h6>2500+ आनंदी ग्राहक</h6>
            </div>
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default Home;
