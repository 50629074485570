import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import './Navrouter.css';
import logo from './IMAGES/header_latest_logo.png'
import { MdPhoneInTalk ,MdMessage } from "react-icons/md";
import { Link } from 'react-scroll';

const Navrouter = () => {
  return (
    <>
      <Navbar expand="lg" className="header-navbar">
        <Container className="align-items-center justify-content-between">
          <Navbar.Brand href="#home" className="brand-logo">
            <img
              src={logo} // Replace with your logo path
              alt="Logo" 
              className="logo"
            />
            <div className="brand-text">
              <span className="brand-name">Shree Samarth Jyotisha Kendra</span>
              <small className="brand-tagline">Past • Present • Beyond</small>
            </div>
          </Navbar.Brand>
          <Nav className="ml-auto align-items-center">
          <a href='https://wa.link/b1gqtu'><Button variant="outline-secondary" className="header-button chat-button">
            <MdMessage className="icon-chat" /> Chat with Consultant
          </Button>
          </a>
        <a  href="tel:+917558447044" >  <Button variant="outline-secondary" className="header-button talk-button">
            <MdPhoneInTalk className="icon-chat" /> Talk to Consultant
          </Button>
          </a>
          
          </Nav>
        </Container>
      </Navbar>
      <div className="navbar-submenu">
        <Container>
          <Nav>
            <Link to='home' className='submenu'>Home</Link>
            <Link to='services' className='submenu'>Services</Link>
            <Link to='stones' className='submenu'>Stones</Link>
            <Link to='pooja' className='submenu'>Puja</Link>
            <Link to='yantra' className='submenu'>Yantra</Link>
            <Link to='contact' className='submenu'>Contact</Link>
          </Nav>
        </Container>
      </div>
    </>
  );
};

export default Navrouter;
