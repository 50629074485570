import React from 'react';
import './Stones.css';
import panna from './IMAGES/panna.jpeg';
import moti from './IMAGES/moti.jpeg';
import heera from './IMAGES/heera.jpeg';
import manikya from './IMAGES/manikya.jpeg';
import nilam from './IMAGES/nilam.jpeg';
import gomed from './IMAGES/gomed.jpeg';
import moonga from './IMAGES/munga.jpeg';
import pushkaraj from './IMAGES/puskaraj.jpg'
import fimukhi from './IMAGES/5-mukhi.jpeg'
import simukhi from './IMAGES/6 mukhi.webp'
import semukhi from './IMAGES/7-mukhi.jpeg'
import emukhi from './IMAGES/8-mukhi.jpeg'
import nmukhi from './IMAGES/9 mukhi.jpeg'
import omukhi from './IMAGES/1mukhi.jpeg'
import tmukhi from './IMAGES/3-mukhi.jpeg'
import twmukhi from './IMAGES/2-mukhi.jpeg'
import foumukhi from './IMAGES/4-mukhi.jpeg'
import lasuniratna from './IMAGES/lasuniya.jpeg'


const stones = [
  { id: 1, name: 'Panna (पन्ना)', image: panna ,price:'7500',link: 'https://topmate.io/ganesh_shastri10/1094659',},
  { id: 2, name: 'Moti (मोती)', image: moti ,price:'7300',link: 'https://topmate.io/ganesh_shastri10/1095502',},
  { id: 3, name: 'Heera (हिरा)', image: heera,price:'11000',link: 'https://topmate.io/ganesh_shastri10/1095523',},
  { id: 4, name: 'Manikya (रुबी)', image: gomed ,price:'9500',link: 'https://topmate.io/ganesh_shastri10/1095528',},
  { id: 5, name: 'Neelam (नीलम)', image: nilam,price:'9500 ',link: 'https://topmate.io/ganesh_shastri10/1095541',},
  { id: 6, name: 'Gomedh (गोमेद)', image:  manikya ,price:'12500',link: 'https://topmate.io/ganesh_shastri10/1095552',},
  { id: 7, name: 'Moonga (मुंगा)', image: moonga,price:'11500',link: 'https://topmate.io/ganesh_shastri10/1095553', },
  { id: 8, name: 'Pushkraj (पुष्कराज)', image:pushkaraj ,price:'16000',link: 'https://topmate.io/ganesh_shastri10/1095563',},
  { id: 9, name: 'लसुनिया', image:lasuniratna,price:'4501',link: 'https://topmate.io/ganesh_shastri10/1095571',},

  {
    id:10,
    name:'1 मुखी (रुद्राक्ष)',
    image: omukhi,
    price:'6001',
    link: 'https://topmate.io/ganesh_shastri10/1095574',
  },
  {
    id:11,
    name:'२ मुखी (रुद्राक्ष)',
    image: twmukhi,
    price:'6001',
    link: 'https://topmate.io/ganesh_shastri10/1095579',
  },
  {
    id:12,
    name:'३ मुखी (रुद्राक्ष)',
    image: tmukhi,
    price:'6001',
    link: 'https://topmate.io/ganesh_shastri10/1095580',
  },
  {
    id:13,
    name:'४ मुखी (रुद्राक्ष)',
    image: foumukhi,
    price:'6501',
    link: 'https://topmate.io/ganesh_shastri10/1095588',
  },
  {
    id:14,
    name:'५ मुखी (रुद्राक्ष)',
    image: fimukhi,
    price:'6501',
    link: 'https://topmate.io/ganesh_shastri10/1095593',
  },
  {
    id:15,
    name:'६ मुखी (रुद्राक्ष)',
    image: simukhi,
    price:'7201',
    link: 'https://topmate.io/ganesh_shastri10/1095594',
  },
  {
    id:16,
    name:'७ मुखी (रुद्राक्ष)',
    image: semukhi,
    price:'6101',
    link: 'https://topmate.io/ganesh_shastri10/1095605',
  },
  {
    id:17,
    name:'८ मुखी (रुद्राक्ष)',
    image: emukhi,
    price:'8201',
    link: 'https://topmate.io/ganesh_shastri10/1095610',
  },
  {
    id:18,
    name:'९ मुखी (रुद्राक्ष)',
    image: nmukhi,
    price:'6201',
    link: 'https://topmate.io/ganesh_shastri10/1095611',
  },
];

const Stones = () => {
  return (
    <div className="stones-container">
      <section className='samasya'>
        <p className='samasya-text' style={{ color: "white", fontSize: '2rem',fontWeight:"bold",paddingTop:"2vw",marginLeft:"13vw",marginRight:"13vw" }}>समस्या नाही असा मनुष्य नाही ,आणि  उपाय नाही अशी समस्या  नाही.  </p>
      </section>
      <p className='ratna-head'>रत्न विवरण</p>
      <p className='ratna-p'>पुजा विधान आणि मंत्रासह प्रमाणित आणि मूळ राशी रत्न (रुबी, मोती, पन्ना, कोरल, पुष्कराज, नीलम, डायमंड, गोमेद, लसूण) कोरल, एमराल्ड, क्रिस्टल आणि मर्क्युरी गणपती उपलब्ध
      2 मुखी ते 14 मुखी रुद्राक्ष शुद्धीकरण विधी आणि मंत्रांसह.</p>
      <div className="stones-list">
        {stones.map((stone) => (
          <div
            key={stone.id}
            className="stone"
          >
            <img src={stone.image} className='stone-img' alt={stone.name} />
            <div className='stone-name'>{stone.name}</div>
            <p className='stone-price'>&#8377;{stone.price}</p>
           <a  href={stone.link} > <button className='buy-now-button ' > Buy Now</button>  </a>     
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stones;
