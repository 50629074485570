import React, { useState } from 'react';
import './Contact.css';
import swami from './IMAGES/swami (2).jpg'

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const linkwhatsapp = () => {
      const { name, email, phone, message } = formData;
      const whatsappMessage = `माझे नाव : ${name},पत्ता : ${email}  मोबाइल नं : ${phone}. : ${message}`;
      const encodedMessage = encodeURIComponent(whatsappMessage);
      window.open(`https://api.whatsapp.com/send?phone=+917558447044&text=${encodedMessage}`, '_blank');
  };
  
  

    return (
        <div className="contact-container">
            <div className="contact-info-section">
            <div className="contact-info-item">
          <div className="contact-icon">📍</div>
          <div className="contact-text">
            <p className='head'>ADDRESS</p>
            <p>Trimbakeshwar Nashik , (Maharashtra) – India</p>
          </div>
        </div>
        <div className="contact-info-item">
          <div className="contact-icon">📧</div>
          <div className="contact-text">
            <p className='head'>EMAIL ADDRESS</p>
            <p>info@Ganeshh@gmail.com</p>
          </div>
        </div>
        <div className="contact-info-item">
          <div className="contact-icon">📞</div>
          <div className="contact-text">
            <p className='head'>PHONE NUMBER</p>
            <p> +917558447044</p>
          </div>
        </div>
            </div>

            <div className="details-section">
                   <div className="account-details">
         
          <img className="qr-code" src={swami} alt="UPI QR Code" />
        </div>
                <div className="inquiry-form">
                    <h3 className="form-heading">GET IN TOUCH</h3>
                    <form className="form">
                        <input
                            className="form-input"
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                        <input
                            className="form-input"
                            type="String"
                            name="email"
                            placeholder="Address"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        <input
                            className="form-input"
                            type="tel"
                            name="phone"
                            placeholder="Phone No."
                            value={formData.phone}
                            onChange={handleChange}
                        />
                        <textarea
                            className="form-textarea"
                            name="message"
                            placeholder="Message..."
                            value={formData.message}
                            onChange={handleChange}
                        ></textarea>
                        <button className="form-button" type="button" onClick={linkwhatsapp}>
                            Send Message
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
