import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section pandit-section">
          <h3 className="section-title">Pandit Ganesh Ji Shastri</h3>
          <p className="section-description">Expert in Vedic astrology, Pandit Ganesh Ji Shastri provides insights and solutions for all your life problems through ancient astrological practices.</p>
        </div>
        <div className="footer-section quick-links-section">
          <h3 className="section-title">Quick Links</h3>
          <ul className="quick-links-list">
            <li><a href="home" className="quick-link">Home</a></li>
            <li><a href="yantra" className="quick-link">Yantra</a></li>
            <li><a href="services" className="quick-link">Services</a></li>
            <li><a href="stones" className="quick-link">Stones</a></li>
            <li><a href="contact" className="quick-link">Contact</a></li>
          </ul>
        </div>
        <div className="footer-section social-icons-section">
        <h3 className="follow-title">Follow Us</h3>
          <div className="social-icons">
            <a href="" className="social-icon"><FaFacebookF size={30} /></a>
            <a href="#" className="social-icon"><FaTwitter size={30} /></a>
            <a href="#" className="social-icon"><FaInstagram size={30} /></a>
          </div>
         <a  href="tel:+917558447044" >  <button className="call-us-button" >Call Us Now</button></a>
        </div>
      </div>
      <a href='https://shreedigimarketing.in/' className='copyright'>  <p className="copyright">&copy; 2024 Shree Samarth Jyotisha Kendra | All rights reserved By Shree Digital Marketing Agency</p>
      </a>
    </footer>
  );
}

export default Footer;
