import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import kalsarpyantra from './IMAGES/kaalsarp.jpg';
import lakshmiyantra from './IMAGES/WhatsApp Image 2024-07-02 at 9.33.25 PM.jpeg';

import naugrahyantra from './IMAGES/WhatsApp Image 2024-07-02 at 9.33.28 PM.jpeg';
import vyaparvrudhhi from './IMAGES/WhatsApp Image 2024-07-02 at 9.33.26 PM (1).jpeg'
import manokamana from './IMAGES/sarva manokamana siddhi yantra.jpeg'
import kashtnivaran from './IMAGES/kashta nivaran.jpeg'
import sarvasiddhikarkar from './IMAGES/sarvakaryasiddhikaryayantra.jpeg'
import vastuyantram from './IMAGES/vastuyantam.jpeg'
import './Yantra.css'; // Import your CSS file

const Yantra = () => {
  // Example data of cards
  const cards = [
    { id: 1, image: kalsarpyantra,
       name: 'कालसर्प  यंत्र',
        info: 'आर्थिक प्रगति होऊन भरभराट होते,अडकलेले पैसे पुन्हा प्राप्त होतात,शत्रूंचा त्रास नष्ट होऊन मित्रांचे साहाय्य प्राप्त होतेकौटुंबिक सौख्य लाभते,पती-पत्नीमधील वादविवाद मिटतात,नोकरीमध्ये पदोन्नती होते,व्यवसायात यश मिळते. कर्ज मिटते,दांपत्यास संतती प्राप्त होते,धर्मावरील श्रद्धा दृढ होऊन आस्तिक्य स्थापित होते,सामाजिक पत सुधारते. पद-प्रतिष्ठा, मान-सन्मान पुन्हा प्राप्त होतो.',
        price:'4200',
        link: 'https://topmate.io/ganesh_shastri10/1096357',
       },
    { id: 2,
       image: lakshmiyantra,
       name: 'संपूर्ण  महालक्ष्मी  यंत्र ',
       info: 'आपल्या धन समृद्धिला दुप्पट करण्याची खासियत आहे या लक्ष्‍मी कुबेर यंत्रात. कुठल्याही प्रकारची परेशानी असेल तर लक्ष्‍मी कुबेर यंत्र तुमच्या साठी अत्यंत लाभकारी सिद्ध होइल. या यंत्रात स्वयं धनची देवी आणि समृद्धिचे राजा कुबेर वास करतात.',
       price:'3200',
       link: 'https://topmate.io/ganesh_shastri10/1096358', 
      },
    { id: 3,
       image: naugrahyantra,
        name: 'श्री संपूर्ण नवग्रह यंत्र', 
        info: 'नवग्रहांना शांत करण्या साठी श्री संपूर्ण नवग्रह यंत्र अत्‍यंत प्रभावकारी आहे. सौरमंडळाच्या 9 ग्रहांचा आपल्या जीवनावर अनुकूल-प्रतिकूल प्रभाव पडतो. श्री संपूर्ण नवग्रह यंत्र नवग्रहाना प्रतिबिंबित करतात.',
        price:'2500',
        link: 'https://topmate.io/ganesh_shastri10/1095593',
       },
    { id: 4,
       image: vyaparvrudhhi,
        name: 'संपूर्ण व्यापारवृद्धी  यंत्र ',
         info: 'व्‍यापार वृद्धि यंत्रा मुळे न केवळ व्यापारात होत असणाऱ्या नुकसाना पासून वाचता बल्की व्यापारात वाढ होण्या साठी देखील हे यंत्र कारगर आणि  चमत्‍कारिक आहे.',
         price:'3500',
        link: 'https://topmate.io/ganesh_shastri10/1096371',
       },
    { id: 5,
       image: manokamana,
        name: 'सर्व मनोकामना सिद्धी यंत्र ',
         info: 'सर्व मनोकामना सिद्धी यंत्र आपल्या सर्व समस्यांचे निराकरण करते . सर्व मनोकामना सिद्धी  यंत्र हे वैश्विक वैश्विक शक्तींशी जोडण्याचे स्त्रोत आहे. दुसऱ्या शब्दांत याला देवाची प्रतिमा म्हटल्यास चुकीचे ठरणार नाही.',
         price:'4100',
        link: 'https://topmate.io/ganesh_shastri10/1096381',
       },
    { id: 6,
       image: kashtnivaran,
        name: 'संपूर्ण सर्व कष्ट निवारण यंत्र ',
         info: 'सर्व कष्ट निवारण यंत्र हे यश मिळवण्यासाठी आणि तुमच्या जीवनातील अडथळ्यांवर मात करण्यासाठी एक शक्तिशाली साधन आहे. अडथळे दूर करणे, चांगले भाग्य आकर्षित करणे, नातेसंबंध सुधारणे आणि करिअरच्या संधी वाढवणे यासह त्याचे फायदे असंख्य आहेत',
         price:'3500',
       link: 'https://topmate.io/ganesh_shastri10/1096382',
       },
    { id: 7,
       image: sarvasiddhikarkar,
        name: 'श्री सर्वकार्य सिध्दीयंत्र ',
         info: 'सर्वकार्य सिद्धी यंत्र , नावाप्रमाणेच, यश, शांती आणि समृद्धीच्या मार्गातील अडथळे आणि अडचणी दूर करण्यासाठी वापरला जातो',
         price:'2500',
         link: 'https://topmate.io/ganesh_shastri10/1096385', },
    { id: 8,
       image: vastuyantram,
        name: 'संपूर्ण वास्तूयंत्र ', 
        info: 'वास्तुयंत्र तुमच्या घरासाठी सुरक्षित ऊर्जा क्षेत्र तयार करून नकारात्मक ऊर्जा आणि वाईट प्रभावापासून तुमच्या घरासाठी संरक्षणात्मक कवच म्हणून काम करते.',
        price:'2500',
        link: 'https://topmate.io/ganesh_shastri10/1096387', },

  ];

  return (
    <div>
      <p className='yantra-head'>आमच्याकडे सिद्ध जप अनुष्ठान झालेले यंत्र मिळतील </p>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {cards.map((card) => (
          <div key={card.id} className="card-container">
            <img src={card.image} alt={`Card ${card.id}`} className="card-image" />
            <div className="card-info">
              <div className="info-content">
                <div className="card-name">{card.name}</div>
                <p>{card.info}</p>
                <p>{card.price}</p>
                
                < a href={card.link} ><button className="buy-button">Buy Now</button></a>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Yantra;
